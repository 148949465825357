import { Card, CardContent, CardHeader, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import React, { useEffect, useState } from 'react'
import userStore from 'store/userStore'
import { postDataWithResponse } from 'global/postFunc'
import SoftTypography from 'components/SoftTypography'
import { AddCircleOutline } from '@mui/icons-material'
import Tambah from './Tambah'
import { getDataWithResponse } from 'global/getFunc'
import ServerSideTable from 'myComponent/Table/ServerSideTable'
import { postDelete } from 'global/postFunc'
import Swal from 'sweetalert2'
import * as XLSX from 'xlsx';
import MyTable from 'myComponent/Table/MyTable'

const DTD = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const [tableData, setTableData] = useState({
        data: [],
        rowCount: 0
    });
    const [deleting, setDeleting] = useState(false)

    const [loading, setLoading] = useState(true)

    const [listKorcam, setListKorcam] = useState([])
    const [korcam, setKorcam] = useState('')
    const [listRelawan, setListRelawan] = useState([])
    const [relawan, setRelawan] = useState('')
    const [listVg, setListVg] = useState([])
    const [vg, setVg] = useState('')

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10, //customize the default page size
    });
    const [columnFilter, setColumnFilter] = useState([])

    const [sumberData, setSumberData] = useState('')

    useEffect(() => {
        reqKorcam()
        // requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reqKorcam = () => {
        addReq()
        getDataWithResponse(
            '/entry/korcam-all',
            decryptedUser,
            (res) => {
                setListKorcam(res)
            },
            (res) => {
                console.log(res);
            }
        );
    }

    useEffect(() => {
        let sd = null;
        if (vg !== '') {
            console.log('Req Vg');
            sd = vg.nik
            setSumberData(vg)
        }
        else if (relawan !== '') {
            console.log('Req Rl');
            sd = relawan.nik
            setSumberData(relawan)
        }
        else if (korcam !== '') {
            console.log('Req Cam');
            sd = korcam.nik
            setSumberData(korcam)
        }

        if (sd !== null) {
            requestData(sd)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting, korcam, relawan, vg])

    useEffect(() => {
        reqRelawan(korcam)
        setRelawan('')
        setVg('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [korcam])

    useEffect(() => {
        reqVg(relawan)
        setVg('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relawan])

    const reqVg = (param) => {
        if (param === '') {
            setListVg([])
        } else {
            addReq()
            postDataWithResponse(
                '/entry/vote-getter',
                {
                    sumber_data_username: param.nik,
                    status: 'Vote Getter'
                },
                decryptedUser,
                (res) => {
                    setLoading(false)
                    setListVg(res)
                },
                (error) => {
                    setLoading(false)
                    console.log(error)
                }
            )
        }
    }

    const reqRelawan = (param) => {
        if (param === '') {
            setListRelawan([])
            setListVg([])
        }
        else {
            addReq();
            postDataWithResponse(
                '/entry/korkel',
                {
                    sumber_data_username: param.nik,
                    status: 'Relawan'
                },
                decryptedUser,
                (res) => {
                    setListRelawan(res)
                },
                (error) => {
                    console.log(error)
                }
            )
        }
    }

    const requestData = (params) => {
        console.log(params);
        addReq();
        setLoading(true)
        postDataWithResponse(
            '/entry/dtd-export',
            {
                sumber_data_username: params
            },
            decryptedUser,
            (res) => {
                setLoading(false)
                setTableData(res)
            },
            (error) => {
                setLoading(false)
                console.log(error)
            }
        )
    }

    const handleChangeKorcam = (event) => {
        if (event.target.value === '') {
            setKorcam('')
        }
        else {
            let resultObject = listKorcam.find(x => x.nik === event.target.value)
            console.log(resultObject);
            setKorcam(resultObject)
        }
    };

    const handleChangeRel = (event) => {
        if (event.target.value === '') {
            setRelawan('')
        }
        else {
            let resultObject = listRelawan.find(x => x.nik === event.target.value)
            console.log(resultObject);
            setRelawan(resultObject)
        }
    };

    const handleChangeVg = (event) => {
        if (event.target.value === '') {
            setVg('')
        }
        else {
            let resultObject = listVg.find(x => x.nik === event.target.value)
            console.log(resultObject);
            setVg(resultObject)
        }
    };

    const columns = [
        //fields = { ['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
        //headers = { ['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
        {
            accessorKey: 'no_kk',
            header: 'No KK',
            filterVariant: 'text',
        },
        {
            accessorKey: 'nik',
            header: 'NIK'
        },
        {
            accessorKey: 'nama',
            header: 'Nama'
        },
        {
            accessorKey: 'id_kec',
            header: 'Kec',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2' >{cell.row.original.nama_kec}</Typography>
            ),
            enableColumnFilter: false
        },
        {
            accessorKey: 'id_kel',
            header: 'Kel/Desa',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2' >{cell.row.original.nama_kel}</Typography>
            ),
            enableColumnFilter: false
        },
        {
            accessorKey: 'tempat_lahir',
            header: 'Tmp Lahir'
        },
        {
            accessorKey: 'tgl_lahir',
            header: 'Tgl Lahir'
        },
        {
            accessorKey: 'sumber_data_username',
            header: 'Tim',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2' >{cell.row.original.sumber_data_nama}</Typography>
            ),
            enableColumnFilter: false
        },
    ]

    const columnVisibility = {
        no_kk: false,
        tempat_lahir: false,
        tgl_lahir: false,
        // tgl_lahir: false
    }

    const deleteData = (params) => {
        Swal.fire({
            title: 'Hapus Data?',
            icon: 'warning',
            text: "Hapus Data " + params.nik + '?',
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#dd3333',
            // cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                postDelete(
                    '/entry/dtd/delete',
                    {
                        _method: 'DELETE',
                        nik: params.nik
                    },
                    decryptedUser,
                    () => {
                        setLoading(false)
                        setDeleting(!deleting)
                    },
                    (error) => {
                        setLoading(false)
                        console.log(error);
                    }
                )
            }
        })
    }

    const generateFileName = (params) => {
        let fileName = 'Export';
        if (params.status === 'Korcam') {
            fileName = 'Korcam_' + params.nama_kec + '_' + params.nama
        }
        else if (params.status === 'Relawan') {
            fileName = 'Kordus_korling_' + params.nama_kel + '_' + params.nama
        }
        else {
            fileName = 'VG_' + params.nama_kel + '_' + params.nama
        }
        return fileName;
    }

    const exportData = (params) => {
        let exportData = [];

        params.map((dt, index) => (
            exportData.push({
                no: index + 1,
                nik: dt.nik,
                nama: dt.nama,
                kec: dt.nama_kec,
                keldesa: dt.nama_kel,
                s: {
                    border: {
                        top: {
                            style: 'thin',
                            color: '000000'
                        },
                        bottom: {
                            style: 'thin',
                            color: '000000'
                        },
                        left: {
                            style: 'thin',
                            color: '000000'
                        },
                        right: {
                            style: 'thin',
                            color: '000000'
                        },
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: '1', // any truthy value here
                    },
                    fill: { // background color
                        patternType: "solid",
                        fgColor: { rgb: "b2b2b2" },
                        bgColor: { rgb: "b2b2b2" }
                    }
                }
            })
        ))
        const header = [
            'NO',
            'NIK',
            'NAMA',
            'KEC',
            'DESA/KEL',
            'KETERANGAN'
        ];

        const worksheet = XLSX.utils.aoa_to_sheet([
            ['DATA TIM'],
            [''], 
            ['KECAMATAN', ': '+sumberData.nama_kec],
            ['DESA/KELURAHAN', ': '+sumberData.nama_kel],
            ['KORCAM', ': '+ korcam?.nama + '(' + korcam.no_hp + ')' || ''],
            ['KORDUS/KORLING', relawan.nama === undefined ? ': -' : ': ' + relawan.nama + '(' + relawan.no_hp + ')'],
            ['VG', vg.nama === undefined ? ': -' : ': '+vg.nama + '(' + vg.no_hp + ')'],
        ], {origin: 'B1'})

        // const worksheet = utils.json_to_sheet(exportData);
        XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A9' })
        XLSX.utils.sheet_add_json(worksheet, exportData, { origin: 'A10', skipHeader: true });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'DTD')

        console.log(exportData);


        let wscol = []
        header.map((arr) => (
            wscol.push({ wch: arr.length + 5 })
        ))
        worksheet["!col"] = wscol;

        XLSX.writeFile(workbook, generateFileName(sumberData) + '.xlsx', {
            compression: true,
            cellStyles: true
        })
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tableData?.rowCount || tableData?.length || 0}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography>
                                    Tabel Pendataan
                                </Typography>
                            }
                            action={
                                <Tambah
                                    text="Tambah"
                                    endIcon={<AddCircleOutline color='white' />}
                                    variant="contained"
                                    disabled={korcam === ''}
                                    korcam={korcam}
                                    relawan={relawan}
                                    vg={vg}
                                    deleting={deleting}
                                    setDeleting={setDeleting}
                                />
                            }
                        />

                        <CardContent>
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={12} lg={4}>
                                    <FormControl fullWidth>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Pilih Korcam
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            defaultValue=''
                                            value={korcam.nik || ''}
                                            label="Relawan"
                                            onChange={handleChangeKorcam}
                                            required
                                        >
                                            <MenuItem value=''><em>Pilih Relawan</em></MenuItem>
                                            {
                                                listKorcam.map((rel) => (
                                                    <MenuItem key={rel.nik} value={rel.nik}>{rel.nama} ({rel.nama_kec})</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl fullWidth>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Pilih Kordus/Korling
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            defaultValue=''
                                            value={relawan.nik || ''}
                                            label="Relawan"
                                            onChange={handleChangeRel}
                                            required
                                        >
                                            <MenuItem value=''><em>Pilih Relawan</em></MenuItem>
                                            {
                                                listRelawan.map((rel) => (
                                                    <MenuItem key={rel.nik} value={rel.nik}>{rel.nama} ({rel.nama_kel})</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl fullWidth>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Pilih Vote Getter
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            defaultValue=''
                                            value={vg.nik || ''}
                                            label="Vote Getter"
                                            onChange={handleChangeVg}
                                            required
                                        >
                                            <MenuItem value=''><em>Pilih Vote Getter</em></MenuItem>
                                            {
                                                listVg.map((vg) => (
                                                    <MenuItem key={vg.nik} value={vg.nik}>{vg.nama}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                    <MyTable
                                        data={tableData ?? []}
                                        columns={columns}
                                        baseUrl="/entry/pemilih"
                                        columnVisibility={columnVisibility}
                                        deleteFunc={deleteData}
                                        exportFunc={exportData}
                                    />
                                    {/* <ServerSideTable
                                        loading={loading}
                                        data={tableData.data}
                                        columns={columns}
                                        rowCount={tableData.rowCount}
                                        baseUrl="/entry/pemilih"
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columnFilter={columnFilter}
                                        setColumnFilter={setColumnFilter}
                                        columnVisibility={columnVisibility}
                                        deleteFunc={deleteData}
                                    /> */}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default DTD